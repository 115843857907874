import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "./Blog.css";
import {
    AiOutlineGitlab,
    AiOutlineGlobal,
    AiOutlineMeh,
    AiOutlineRadarChart,
    AiOutlineTeam,
} from "react-icons/ai";
import { SiOracle } from "react-icons/si";
import DedicatedResources from "../../assets/img/home-it/Dedicated-Resources.png";
import ItConsultancyServices from "../../assets/img/home-it/IT-Consultancy-Services.png";
import ItOutsource from "../../assets/img/home-it/IT-Outsource.png";
import Oracleconsulting from "../../assets/img/home-it/Oracle-consulting.png";
import SoftwareDevelopement from "../../assets/img/home-it/Software-Developement.png";
import { FiChevronUp } from "react-icons/fi";
import { MdDoubleArrow } from "react-icons/md";
import { FaAmericanSignLanguageInterpreting } from "react-icons/fa";
import reasons from "../../assets/img/Blog-section/5-Reasons-Why-You-Need-a-Digital-Marketing-Company.jpg"
import challenges from "../../assets/img/Blog-section/Challenges-in-Digital-Marketing-and-How-to-Overcome-Them.jpg"
import future from "../../assets/img/Blog-section/The-Future-of-E-commerce-and-Digital-Marketing-Navigating-the-Next-Decade.jpg"
import importance from "../../assets/img/Blog-section/The-Importance-of-User-Generated-Content-in-Digital-Marketing.jpg"
import build from "../../assets/img/Blog-section/how-to-build-a-strong-online-brand-presence.jpg"
import inflencer from "../../assets/img/Blog-section/influencer-section.png"
import emailmarketing from "../../assets/img/Blog-section/email-marketing.jpg"
import ai from "../../assets/img/Blog-page/ai-section.jpg"
import impact from "../../assets/img/Blog-page/The-Impact-of-Video-Marketing-Elevating-Engagement-and-Driving-Results-section.png"
import successful from "../../assets/img/Blog-page/Most-Successful-Digital-Marketing-Campaigns-What-We-Can-Learn.jpg"

function InformationTechnology() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <section className="information-technology-section  blog-section pb-0 pt-5 bg-white">
            <Container fluid className="position-relative px-lg-4">
                <Row className="align-items-start justify-content-center">
                    <Col lg={10}>
                        <h2 className="main-title main-title-3 wow animate__animated animate__fadeInUp text-center">
                            Blogs
                        </h2>
                        <p className="main-text fst-italic wow animate__animated animate__fadeInUp text-center m-1">
                            Global Insights: Trends Shaping Tomorrow

                        </p>
                        <p className="main-text wow animate__animated animate__fadeInUp text-center mb-3">
                            Keep up with the latest trends and ideas from different industries across the globe. Our blog shares easy-to-understand updates on new technologies, fashion, and changing customer choices. Whether you’re a business owner or just curious about what’s new, this is the place to learn and stay informed with Global Trendz.






                        </p>
                    </Col>
                    <MultiCarousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        infinite={false}

                    >
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/2024's-most-successful-digital-marketing-campaigns">
                                    <img
                                        src={successful}
                                        className="img-fluid service-visual"
                                        alt="Most Successful Digital Marketing Campaigns: What We Can Learn
"
                                    />
                                </a>

                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>10-12-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/2024's-most-successful-digital-marketing-campaigns">2024's Most Successful Digital Marketing Campaigns: What We Can Learn









                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Digital marketing in 2024 saw some truly remarkable campaigns that not only caught the attention of audiences but also delivered outstanding results. By taking a closer look at these campaigns, we can learn valuable lessons on how to approach our marketing strategies in the future.</p>
                                    <div className="read-more-button">
                                        <a href="/2024's-most-successful-digital-marketing-campaigns" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/the-impact-of-video-marketing-elevating-engagement-and-driving-results">
                                    <img
                                        src={impact}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>

                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>30-11-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/the-impact-of-video-marketing-elevating-engagement-and-driving-results">The Impact of Video Marketing: Elevating Engagement and Driving Results









                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Video marketing has become one of the most powerful tools for businesses looking to engage with their audience, boost brand awareness, and drive conversions. In an increasingly digital world, consumers are seeking dynamic, visual content that grabs their attention and delivers information quickly and effectively. From social media platforms to websites, videos are proving to be an essential component of any successful marketing strategy.</p>
                                    <div className="read-more-button">
                                        <a href="/the-impact-of-video-marketing-elevating-engagement-and-driving-results" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/the-role-of-ai-and-automation-in-digital-marketing">
                                    <img
                                        src={ai}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>

                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>16-11-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/the-role-of-ai-and-automation-in-digital-marketing">The Role of AI and Automation in Digital Marketing



                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Artificial intelligence (AI) and automation have transformed the digital marketing landscape, enabling businesses to engage customers more effectively, optimize campaigns, and drive better results with less manual effort. </p>
                                    <div className="read-more-button">
                                        <a href="/the-role-of-ai-and-automation-in-digital-marketing" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/the-role-of-ai-and-automation-in-digital-marketing">
                                    <img
                                        src={ai}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>

                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>16-11-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/the-role-of-ai-and-automation-in-digital-marketing">The Role of AI and Automation in Digital Marketing



                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Artificial intelligence (AI) and automation have transformed the digital marketing landscape, enabling businesses to engage customers more effectively, optimize campaigns, and drive better results with less manual effort. </p>
                                    <div className="read-more-button">
                                        <a href="/the-role-of-ai-and-automation-in-digital-marketing" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>

                            </div>
                        </div> */}
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/best-strategies-for-successful-email-marketing-campaign">
                                    <img
                                        src={emailmarketing}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>

                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>09-11-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/best-strategies-for-successful-email-marketing-campaign">Best Strategies for Successful Email Marketing Campaign


                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Email marketing remains one of the most powerful tools for businesses to connect with their audience, build relationships, and drive conversions. With the right strategy, email marketing can deliver a high return on investment (ROI), outperforming many other digital marketing channels.  </p>
                                    <div className="read-more-button">
                                        <a href="/best-strategies-for-successful-email-marketing-campaign" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/influencer-marketing-how-to-choose-the-right-partners">
                                    <img
                                        src={inflencer}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>

                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>26-10-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/influencer-marketing-how-to-choose-the-right-partners">Influencer Marketing: How to Choose the Right Partners

                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        In today’s digital landscape, influencer marketing has emerged as a powerful tool for brands to reach targeted audiences in a more authentic and engaging way. By partnering with influencers (individuals with a dedicated following on social media or other platforms), brands can tap into their influence and credibility to promote products or services.  </p>
                                    <div className="read-more-button">
                                        <a href="/influencer-marketing-how-to-choose-the-right-partners" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/5-reasons-why-you-need-a-digital-marketing-company">
                                    <img
                                        src={reasons}
                                        className="img-fluid service-visual"
                                        alt="5 Reasons Why You Need a Digital Marketing Company"
                                    />
                                </a>
                                {/* <p className="fst-italic mb-0">Providing Robust Solutions</p> */}
                                <div className="blog-content-card">
                                    <div className="ps-0">
                                        <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                        <span>24-09-2024</span>
                                    </div>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/5-reasons-why-you-need-a-digital-marketing-company">5 Reasons Why You Need a Digital Marketing Company</a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        In the current digital era, a company's ability to prosper and grow depends on its online presence. It might be difficult to differentiate yourself from the competition and successfully reach your target audience given the abundance of businesses in every area.                                 </p>
                                    <div className="read-more-button">
                                        <a href="/5-reasons-why-you-need-a-digital-marketing-company" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>
                                {/* <a
                                    href={`${"software development".replace(/ /g, "-")}`}
                                    className="service-read-more"
                                >
                                    <MdDoubleArrow />
                                </a> */}
                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/challenges-in-digital-marketing-and-how-to-overcome-them">
                                    <img
                                        src={challenges}
                                        className="img-fluid service-visual"
                                        alt="Challenges in Digital Marketing and How to Overcome Them"
                                    />
                                </a>
                                {/* <p className="fst-italic mb-0">Revamp Your IT Environment</p> */}
                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>24-09-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/challenges-in-digital-marketing-and-how-to-overcome-them">
                                            Challenges in Digital Marketing and How to Overcome Them</a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Digital marketing has become an essential component of any business strategy, offering unparalleled opportunities to reach and engage with target audiences. </p>
                                    <div className="read-more-button">
                                        <a href="/challenges-in-digital-marketing-and-how-to-overcome-them" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>
                                {/* <a
                                    href={`${"it consulting".replace(/ /g, "-")}`}
                                    className="service-read-more"
                                >
                                    <MdDoubleArrow />
                                </a> */}
                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/the-future-of-e-commerce-and-digital-marketing:navigating-the-next-decade">
                                    <img
                                        src={future}
                                        className="img-fluid service-visual"
                                        alt="The Future of E-commerce and Digital Marketing: Navigating the Next Decade"
                                    />
                                </a>
                                {/* <p className="fst-italic mb-0">
                                    Elevating Your Business Performance
                                </p> */}
                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>24-09-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/the-future-of-e-commerce-and-digital-marketing:navigating-the-next-decade">The Future of E-commerce and Digital Marketing: Navigating the Next Decade</a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        As the world becomes increasingly digital, the future of e-commerce and digital marketing is poised to evolve at an unprecedented pace. The rise of new technologies, changing consumer behaviors, and the ongoing global shift towards online shopping are reshaping how businesses operate and interact with their customers. This article explores key trends and innovations that will define the future of e-commerce and digital marketing and how businesses can adapt to stay ahead in this rapidly changing landscape.   </p>
                                    <div className="read-more-button">
                                        <a href="/the-future-of-e-commerce-and-digital-marketing:navigating-the-next-decade" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>
                                {/* <a
                                    href={`${"dedicated resource".replace(/ /g, "-")}`}
                                    className="service-read-more"
                                >
                                    <MdDoubleArrow />
                                </a> */}
                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/the-importance-of-user-generated-content-in-digital-marketing">
                                    <img
                                        src={importance}
                                        className="img-fluid service-visual"
                                        alt="The Importance of User-Generated Content in Digital Marketing"
                                    />
                                </a>
                                {/* <p className="fst-italic mb-0">Enhancing Your IT Solutions</p> */}
                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>24-09-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/the-importance-of-user-generated-content-in-digital-marketing">The Importance of User-Generated Content in Digital Marketing
                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        In today's digital landscape, user-generated content (UGC) has become a powerful asset for businesses aiming to build trust, foster engagement, and drive conversions.                                                              </p>
                                    <div className="read-more-button">
                                        <a href="/the-importance-of-user-generated-content-in-digital-marketing" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>
                                {/* <a
                                    href={`${"it outsource".replace(/ /g, "-")}`}
                                    className="service-read-more"
                                >
                                    <MdDoubleArrow />
                                </a> */}
                            </div>
                        </div>
                        <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/how-to-build-a-strong-online-brand-presence">
                                    <img
                                        src={build}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>
                                {/* <p className="fst-italic mb-0">
                                    Optimizing Oracle Capabilities
                                </p> */}
                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>24-09-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/how-to-build-a-strong-online-brand-presence">How to Build a Strong Online Brand Presence
                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        In today's digital world, having a strong online brand presence is essential for any business. Your online presence is how people find and connect with your brand, so it's important to make a great impression                             </p>
                                    <div className="read-more-button">
                                        <a href="/how-to-build-a-strong-online-brand-presence" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>
                                {/* <a
                                    href={`${"oracle consulting".replace(/ /g, "-")}`}
                                    className="service-read-more"
                                >
                                    <MdDoubleArrow />
                                </a> */}
                            </div>
                        </div>


                        {/* <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/the-role-of-ai-and-automation-in-digital-marketing">
                                    <img
                                        src={build}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>
                              
                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>26-10-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/the-role-of-ai-and-automation-in-digital-marketing">The Role of AI and Automation in Digital Marketing


                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Artificial intelligence (AI) and automation have transformed the digital marketing landscape, enabling businesses to engage customers more effectively, optimize campaigns, and drive better results with less manual effort. By leveraging AI and automation, companies can streamline tasks, personalize marketing efforts, and  </p>
                                    <div className="read-more-button">
                                        <a href="/the-role-of-ai-and-automation-in-digital-marketing" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>
                              
                            </div>
                        </div> */}
                        {/* <div className="technology-card">
                            <div className="technology-card-inner">
                                <a href="/the-impact-of-video-marketing-elevating-engagement-and-driving-results">
                                    <img
                                        src={build}
                                        className="img-fluid service-visual"
                                        alt="How to Build a Strong Online Brand Presence"
                                    />
                                </a>
                               
                                <div className="blog-content-card">
                                    <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                    <span>26-10-2024</span>
                                    <h3 className="how-it-works-card__title text-start heading-content">
                                        <a href="/the-impact-of-video-marketing-elevating-engagement-and-driving-results">The Impact of Video Marketing: Elevating Engagement and Driving Results



                                        </a>
                                    </h3>
                                    <p className="how-it-works-card__text blog-content">
                                        Video marketing has become one of the most powerful tools for businesses looking to engage with their audience, boost brand awareness, and drive conversions. In an increasingly digital world, consumers are seeking dynamic, visual content that grabs their attention and delivers information quickly and effectively.  </p>
                                    <div className="read-more-button">
                                        <a href="/the-impact-of-video-marketing-elevating-engagement-and-driving-results" class="btn btn-primary talk mt-5px">Read More</a>

                                    </div>
                                </div>
                              
                            </div>
                        </div> */}
                    </MultiCarousel>
                </Row>
            </Container>
        </section>
    );
}

export default InformationTechnology;
